// extracted by mini-css-extract-plugin
export var alignDiscLeft = "r_rj d_fp d_bG d_dv";
export var alignLeft = "r_qh d_fp d_bG d_dv";
export var alignDiscCenter = "r_rk d_fq d_bD d_dw";
export var alignCenter = "r_bP d_fq d_bD d_dw";
export var alignDiscRight = "r_rl d_fr d_bH d_dx";
export var alignRight = "r_qj d_fr d_bH d_dx";
export var footerContainer = "r_rm d_dW d_bW";
export var footerContainerFull = "r_rn d_dT d_bW";
export var footerHeader = "r_kf d_kf";
export var footerTextWrapper = "r_rp d_w";
export var footerDisclaimerWrapper = "r_km d_km d_ck";
export var badgeWrapper = "r_rq d_w d_bz d_bP d_bF d_bJ";
export var verticalTop = "r_rr d_bz d_bJ d_bN d_bL";
export var wide = "r_rs d_cy";
export var right = "r_rt d_bK";
export var line = "r_fk d_fl d_cv";
export var badgeDisclaimer = "r_rv d_bC d_bP d_bJ";
export var badgeContainer = "r_rw d_bz d_bH d_bP";
export var badge = "r_rx";
export var padding = "r_ry d_c7";
export var end = "r_rz d_bH";
export var linkWrapper = "r_rB d_dB";
export var link = "r_mC d_dB";
export var colWrapper = "r_rC d_cx";
export var consent = "r_f d_f d_bC d_bP";
export var disclaimer = "r_rD d_bz d_bJ";
export var media = "r_rF d_bx d_dy";
export var itemRight = "r_rG";
export var itemLeft = "r_rH";
export var itemCenter = "r_rJ";
export var exceptionWeight = "r_rK x_sH";