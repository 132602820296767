// extracted by mini-css-extract-plugin
export var tileContent = "s_rL d_w d_H d_Z";
export var teamTextLeft = "s_rM d_dv";
export var teamTextCenter = "s_rN d_dw";
export var teamTextRight = "s_rP d_dx";
export var alignLeft = "s_qh d_w d_bz d_fp d_bG d_dv";
export var alignCenter = "s_bP d_w d_bz d_fq d_bD d_dw";
export var alignRight = "s_qj d_w d_bz d_fr d_bH d_dx";
export var teamContainer = "s_rQ d_dW";
export var teamContainerFull = "s_rR d_dT";
export var teamRowWrapper = "s_rS d_cc";
export var teamTileWrapper = "s_j3 d_j3 d_Z d_cv";
export var teamTileSquareWrapper = "s_rT d_j4 d_Z d_cv";
export var teamTileRoundWrapper = "s_j4 d_j4 d_Z d_cv";
export var teamTileNoGuttersWrapper = "s_j5 d_j5 d_Z";
export var teamHoverNoGutters = "s_j6 d_j6 d_0 d_w d_H d_bz d_bD d_bP d_bm";
export var teamImageText = "s_kc d_kc d_bl d_w d_by";
export var teamInfoWrapperSquare = "s_rV d_j7";
export var teamInfoWrapperRound = "s_j7 d_j7";
export var teamInfoWrapper = "s_rW d_j8 d_0";
export var teamInfoWrapperNoGutters = "s_j9 d_j9 d_w d_H d_bD d_bM d_bJ d_c7";
export var teamImgWrapper = "s_jZ d_jZ";
export var teamImgWrapperAlt = "s_j0 d_j0";
export var teamImgWrapperNoGutters = "s_kb d_kb";
export var teamHeader = "s_rX d_cw";
export var teamHeaderAlt = "s_rY d_cw";
export var teamHeaderNoGutters = "s_rZ d_cw d_cD";